import { intersection, isEqual } from 'underscore';
import { buildFilters, getEventIdsParameter } from 'BetFinder/helpers/helpers';

export const RESET_APP = 'betFinder/RESET_APP';
export const SET_MATCH_COUNT = 'betFinder/SET_MATCH_COUNT';
export const CHANGE_FILTER = 'betFinder/CHANGE_FILTER';
export const INIT_FILTERS = 'betFinder/INIT_FILTERS';
export const SET_MEETINGS = 'betFinder/SET_MEETINGS';
export const SET_EVENT_IDS = 'betFinder/SET_EVENT_IDS';
export const SET_SEARCH_RESULTS = 'betFinder/SET_SEARCH_RESULTS';
export const RESET_SEARCH_RESULTS = 'betFinder/RESET_SEARCH_RESULTS';

export const resetApp = () => ({
  type: RESET_APP,
});

export const setMatchCount = (filters) => ({
  type: SET_MATCH_COUNT,
  filters,
});

export const changeFilter = (filterName, value) => ({
  type: CHANGE_FILTER,
  filterName,
  value,
});

export const setMeetings = (meetings) => ({
  type: SET_MEETINGS,
  meetings,
});

export const setEventIds = (eventIds) => ({
  type: SET_EVENT_IDS,
  eventIds,
});

export const setSearchResults = (response) => ({
  type: SET_SEARCH_RESULTS,
  response,
});

export const resetSearchResults = () => ({
  type: RESET_SEARCH_RESULTS,
});

export const initFilters = (activeFilters = []) => ({
  type: INIT_FILTERS,
  activeFilters,
});

export const initialState = {
  eventIds: [],
  ...buildFilters(),
  total_count: 0,
  meetings: {
    today: [],
    tomorrow: [],
    loaded: false,
  },
  runners: [],
  has_more_result: false,
  fetching: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_APP: {
      return initialState;
    }
    case SET_MATCH_COUNT:
      return { ...state, ...action.filters };
    case CHANGE_FILTER:
      return { ...state, [action.filterName]: action.value, runners: [] };
    case INIT_FILTERS:
      return { ...state, ...buildFilters(action.activeFilters), eventIds: getEventIdsParameter() };
    case SET_MEETINGS: {
      const { meetings } = action;
      const allEventIds = [...meetings.today, ...meetings.tomorrow]
        .flatMap(({ events }) => events.map(({ id }) => id));

      return {
        ...state,
        meetings: { ...meetings, loaded: true },
        eventIds: intersection(state.eventIds, allEventIds),
      };
    }
    case SET_EVENT_IDS:
      if (isEqual(state.eventIds, action.eventIds)) {
        return { ...state, eventIds: [] };
      }

      return { ...state, eventIds: action.eventIds };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        ...action.response,
        runners: [...state.runners, ...action.response.runners],
        fetching: false,
      };
    case RESET_SEARCH_RESULTS:
      return {
        ...state,
        has_more_result: false,
        fetching: true,
        runners: [],
      };
    default:
      return state;
  }
};
