import { MEETING_DAYS } from 'BetFinder/helpers/constants';
import ResultDayButton from './result_day_button';

const ResultDaySelector = () => (
  <div className="bf-results-selector">
    {MEETING_DAYS.map((day) => (
      <ResultDayButton key={day} day={day} />
    ))}
  </div>
);

export default ResultDaySelector;
