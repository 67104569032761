import { t } from 'bv-i18n';

import FilterBox from './filter_box';
import FilterPanel from './filter_panel';
import FilterItem from './filter_item';

const HorseOdds = () => (
  <FilterBox title={t('javascript.bet_finder.find_me_an_edge')}>
    <FilterPanel>
      <FilterItem
        type="trainer_in_form"
        title={t('javascript.bet_finder.trainer_in_form')}
      />
      <FilterItem
        type="jockey_in_form"
        title={t('javascript.bet_finder.jockey_in_form')}
      />
      <FilterItem
        type="beaten_favorite_last_time"
        title={t('javascript.bet_finder.beaten_favorite_last_time')}
      />
      <FilterItem
        type="has_won_last_race"
        title={t('javascript.bet_finder.won_last_race')}
      />
      <FilterItem
        type="has_run_recently"
        title={t('javascript.bet_finder.has_run_recently')}
      />
    </FilterPanel>
  </FilterBox>
);

export default HorseOdds;
