import { property } from 'underscore';
import { addOutcomes, removeOutcomes } from 'sportsbook-outcomes-duck';
import { addMarkets, removeMarkets } from 'sportsbook-markets-duck';
import { setMatchCount, setMeetings, setSearchResults } from 'BetFinder/ducks/bet_finder_store';
import { postMatchCountForm, fetchMeetingsJson, fetchResultsJson } from 'BetFinder/api/bet_finder_api';
import { selectFilterParams, selectResultParams, selectRunners } from 'BetFinder/selectors/bet_finder';
import { resetSearchResults } from './bet_finder_store';

let onHold = false;

const parseOutcomes = (outcomes) => outcomes.map((outcome) => ({
  ...outcome,
  id: outcome.oid,
  formattedPrice: outcome.pr,
}));

export const removeResults = () => (dispatch, getState) => {
  const runners = selectRunners(getState());
  const outcomeIds = runners.map((runner) => runner.outcome_id);
  const marketIds = runners.map(({ market }) => market.id);

  dispatch(resetSearchResults());

  if (outcomeIds.length > 0) {
    dispatch(removeOutcomes(outcomeIds));
  }

  if (marketIds.length > 0) {
    dispatch(removeMarkets(marketIds));
  }
};

export const postMatchCount = () => (dispatch, getState) => {
  if (onHold) {
    return null;
  }

  onHold = true;
  const state = getState();

  return postMatchCountForm(selectFilterParams(state))
    .then((response) => {
      dispatch(setMatchCount(response));
      onHold = false;
    });
};

export const fetchMeetings = () => (dispatch) => (
  fetchMeetingsJson().then((response) => dispatch(setMeetings(response)))
);

export const fetchResults = () => (dispatch, getState) => (
  fetchResultsJson(selectResultParams(getState())).then((response) => {
    dispatch(addOutcomes(parseOutcomes(response.runners.map(property('outcome')))));
    dispatch(addMarkets(response.runners.map(property('market'))));
    dispatch(setSearchResults(response));
  }));
