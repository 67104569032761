import { t } from 'bv-i18n';
import withOutcome from 'sportsbook-with-outcome';

const ResultOutcome = withOutcome(({
  selected, outcome, toggleBetslip, eachway, description,
}) => (
  <div
    className={`bvs-button-sport${selected ? ' active' : ''}`}
    onClick={outcome.h ? () => {} : toggleBetslip}
  >
    <span className="results-price__place-terms">
      {eachway
        ? (
          <>
            {t('javascript.bet_finder.win_ew')}
            <br />
            {description.replace(/EW /, '')}
          </>
        ) : (
          description.toUpperCase()
          || t('javascript.bet_finder.win_only')
        )}
    </span>
    <span className="js-results-price-value results-price__value">
      {outcome.formattedPrice}
    </span>
  </div>
));

export default ResultOutcome;
