export const FILTER_NAMES = [
  'beaten_favorite_last_time',
  'best_backed_horse',
  'has_run_recently',
  'has_won_last_race',
  'has_won_over_course',
  'has_won_over_distance',
  'improving_horses',
  'jockey_in_form',
  'long_odds',
  'medium_odds',
  'short_odds',
  'star_1',
  'star_2',
  'star_3',
  'star_4',
  'star_5',
  'trainer_in_form',
];

export const MEETING_DAYS = ['today', 'tomorrow'];
