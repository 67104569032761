import { useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  selectEventIds, selectFilterValues, selectAllMeetings, selectMeetingsName,
} from 'BetFinder/selectors/bet_finder';
import { fetchMeetings, postMatchCount } from 'BetFinder/ducks/bet_finder_thunks';

import BetFinderHeader from './header';
import BetFinderCourseSelect from './course_select';
import HorseAbility from './horse_ability';
import HorseOdds from './horse_odds';
import FindEdge from './find_edge';
import ShowResultButton from './show_result_button';
import BetFinderFooter from './footer';

const BetFinderLayout = () => {
  const { sportId } = useParams();
  const params = useSelector(selectFilterValues);
  const meetingName = useSelector(selectMeetingsName);
  const meetings = useSelector(selectAllMeetings);
  const eventIds = useSelector(selectEventIds);
  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => { postMatchCount()(dispatch, store.getState); }, [params]);

  useEffect(() => {
    if (eventIds.length && !meetings.length) {
      dispatch(fetchMeetings());
    }
  }, [eventIds, meetings]);

  return (
    <>
      <div id="bet-finder" className="bet-finder page--filters">
        <BetFinderHeader sportId={sportId} />
        <BetFinderCourseSelect meetingName={meetingName} sportId={sportId} />
        <HorseAbility />
        <HorseOdds />
        <FindEdge />
        <BetFinderFooter />
      </div>
      <ShowResultButton sportId={sportId} />
    </>
  );
};

export default BetFinderLayout;
