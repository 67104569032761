import { Link, Icon } from 'bv-components';
import { useParams } from 'react-router-dom';
import { t } from 'bv-i18n';

const BackToFilters = () => {
  const { sportId } = useParams();

  return (
    <div className="bf-back-wrapper">
      <Link
        className="bf-back-button"
        to={`/sports/${sportId}/bet-finder`}
      >
        <Icon id="arrow-left" />
        {t('javascript.bet_finder.back_to_filters')}
      </Link>
    </div>
  );
};

export default BackToFilters;
