import { getParameter } from 'bv-helpers/location';
import { FILTER_NAMES } from './constants';

export const filterActive = (filter) => filter === -1;

export const filterDisabled = (filter) => filter === 0;

export const transformObjToArray = (target, source, previousKey) => (
  Object.entries(source).reduce((acc, [key, value]) => {
    const newKey = previousKey ? `${previousKey}[${key}]` : key;
    if (value instanceof Object && !Array.isArray(value)) {
      return { ...acc, ...transformObjToArray(acc, value, newKey) };
    }
    if (Array.isArray(value)) {
      return { ...acc, [newKey]: value };
    }
    return { ...acc, [newKey]: value };
  }, target)
);

export const transformObjToFormData = (formData, data) => {
  Object.entries(transformObjToArray({}, data)).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        formData.append(`${key}[]`, val);
      });
    } else {
      formData.append(key, value);
    }
  });
};

export const buildParams = (state) => ({
  eventIds: state.eventIds,
  starRating: {
    star1: filterActive(state.star_1),
    star2: filterActive(state.star_2),
    star3: filterActive(state.star_3),
    star4: filterActive(state.star_4),
    star5: filterActive(state.star_5),
  },
  hasWonLastRace: filterActive(state.has_won_last_race),
  hasWonOverDistance: filterActive(state.has_won_over_distance),
  hasWonOverCourse: filterActive(state.has_won_over_course),
  improvingHorses: filterActive(state.improving_horses),
  price: {
    shortOdds: filterActive(state.short_odds),
    mediumOdds: filterActive(state.medium_odds),
    longOdds: filterActive(state.long_odds),
  },
  bestBackedHorse: filterActive(state.best_backed_horse),
  trainerInForm: filterActive(state.trainer_in_form),
  jockeyInForm: filterActive(state.jockey_in_form),
  beatenFavoriteLastTime: filterActive(state.beaten_favorite_last_time),
  hasRunRecently: filterActive(state.has_run_recently),
});

export const getActiveDay = () => getParameter('day', 'TODAY');

export const getArrayParameter = (param) => getParameter(param)?.split(',') || [];

export const getEventIdsParameter = () => getArrayParameter('eventIds')
  .map((eventId) => parseInt(eventId, 10));

export const buildFilters = (activeFilters = []) => FILTER_NAMES.reduce((acc, filter) => ({
  ...acc,
  [filter]: activeFilters.includes(filter) ? -1 : 0,
}), {});

export const buildSearch = (params) => {
  const searchParams = new URLSearchParams();

  ['eventIds', 'filters'].forEach((param) => {
    if (params[param].length) {
      searchParams.set(param, params[param]);
    }
  });

  if (params.day === 'TOMORROW') {
    searchParams.set('day', params.day);
  }

  return searchParams.size ? `?${searchParams}` : null;
};

export const buildResultDaySearch = (day) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('day', day.toUpperCase());
  return `?${searchParams}`;
};

export const isTomorrow = (eventId, meetings) => (
  meetings.tomorrow
    .flatMap(({ events }) => events.map(({ id }) => id))
    .includes(eventId)
);
