import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';
import {
  selectActiveFilters, selectEventIds, selectEventIdsDay, selectTotalCount,
} from 'BetFinder/selectors/bet_finder';
import { buildSearch } from 'BetFinder/helpers/helpers';

const ShowResultButton = ({ sportId }) => {
  const totalCount = useSelector(selectTotalCount) || 0;
  const day = useSelector(selectEventIdsDay);
  const eventIds = useSelector(selectEventIds);
  const filters = useSelector(selectActiveFilters);

  return totalCount > 0 && (
    <div className="bf-results-button-wrapper" style={{ display: 'block' }}>
      <Button
        primary
        to={{
          pathname: `/sports/${sportId}/bet-finder/results`,
          search: buildSearch({ day, eventIds, filters }),
        }}
        className="bf-results-button"
      >
        {t('javascript.bet_finder.filter_button', { total_count: totalCount })}
      </Button>
    </div>
  );
};

ShowResultButton.propTypes = {
  sportId: PropTypes.number.isRequired,
};

export default ShowResultButton;
