import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { selectFilterValue } from 'BetFinder/selectors/bet_finder';
import { filterDisabled, filterActive } from 'BetFinder/helpers/helpers';
import { changeFilter } from 'BetFinder/ducks/bet_finder_store';

const toggleFilter = (onClick, type, value) => () => {
  switch (value) {
    case -1:
      onClick(type, 1);
      break;
    case 0:
      // disabled filter
      break;
    default:
      onClick(type, -1);
  }
};

const FilterItem = ({
  title, type, icons, className,
}) => {
  const dispatch = useDispatch();
  const onClick = (t, v) => dispatch(changeFilter(t, v));
  const value = useSelector((state) => selectFilterValue(state, { type }));

  return (
    <button
      type="button"
      className={classnames({
        'bvs-button-chip': !icons,
        'bf-card__content-item': icons,
        'is-disabled': filterDisabled(value),
        [className]: className,
        active: filterActive(value),
      })}
      onClick={toggleFilter(onClick, type, value)}
    >
      { className && <span className={`bf-card__content-item-icon ${className}`} /> }
      { title && <span className="bf-card__content-item-label">{title}</span> }
    </button>
  );
};

FilterItem.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  icons: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

FilterItem.defaultProps = {
  title: null,
  className: null,
};

export default FilterItem;
