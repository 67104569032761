import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { setEventIds } from 'BetFinder/ducks/bet_finder_store';

const MeetingItem = ({
  label, eventIds, active, sportId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setEventIds(eventIds));
    history.push(`/sports/${sportId}/bet-finder`);
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames({ 'bvs-button-card': true, active })}
    >
      {label}
    </button>
  );
};

MeetingItem.propTypes = {
  label: PropTypes.string.isRequired,
  eventIds: PropTypes.instanceOf(Array).isRequired,
  active: PropTypes.bool.isRequired,
  sportId: PropTypes.number.isRequired,
};

export default MeetingItem;
