import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Spinner } from 'bv-components';
import { getParameter } from 'bv-helpers/location';
import { selectRunners, selectHasMoreResult, selectFetching } from 'BetFinder/selectors/bet_finder';
import { initFilters } from 'BetFinder/ducks/bet_finder_store';
import { fetchResults as fetchResultsAction, removeResults } from 'BetFinder/ducks/bet_finder_thunks';
import { buildResultDaySearch, getArrayParameter } from 'BetFinder/helpers/helpers';
import DaySelector from './result_day_selector';
import EmptyResultBox from './empty_result_box';
import ResultItem from './result_item';
import ShowMoreButton from './result_show_more_button';
import BackButton from './result_back_button';

const ResultPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { sportId } = useParams();
  const resultDay = getParameter('day');
  const runners = useSelector(selectRunners);
  const hasMoreResult = useSelector(selectHasMoreResult);
  const fetching = useSelector(selectFetching);
  const dispatch = useDispatch();

  const fetchResults = () => {
    dispatch(fetchResultsAction());
  };

  useEffect(() => {
    dispatch(initFilters(getArrayParameter('filters')));
    fetchResults();

    return () => { dispatch(removeResults()); };
  }, [resultDay]);

  useEffect(() => {
    if (!fetching && !runners.length && !resultDay) {
      history.replace({
        pathname: location.pathName,
        search: buildResultDaySearch('TOMORROW'),
      });
    }
  }, [fetching, resultDay, runners]);

  return (
    <div className="bet-finder bet-finder--results">
      <BackButton sportId={sportId} />
      <DaySelector />
      { runners.length > 0 && runners.map((r) => <ResultItem runner={r} />) }
      { fetching && <Spinner /> }
      { !fetching && runners.length === 0 && <EmptyResultBox /> }
      { hasMoreResult && <ShowMoreButton showMore={fetchResults} /> }
    </div>
  );
};

export default ResultPage;
