import { t } from 'bv-i18n';
import PropTypes from 'prop-types';

const ShowMoreButton = ({ showMore }) => (
  <button
    type="button"
    className="bvs-button is-primary bf-show-more-button"
    onClick={showMore}
  >
    {t('show_more')}
  </button>
);

ShowMoreButton.propTypes = {
  showMore: PropTypes.func.isRequired,
};

export default ShowMoreButton;
