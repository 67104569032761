import { sanitize } from 'dompurify';
import { t } from 'bv-i18n';

const BetFinderFooter = () => (
  <div
    className="bf-footer"
    dangerouslySetInnerHTML={{ __html: sanitize(t('javascript.bet_finder.timeform_footer')) }}
  />
);

export default BetFinderFooter;
