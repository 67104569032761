import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMeetings } from 'BetFinder/ducks/bet_finder_thunks';
import { MEETING_DAYS } from 'BetFinder/helpers/constants';
import { selectMeetings } from 'BetFinder/selectors/bet_finder';
import { Spinner } from 'bv-components';
import { t } from 'bv-i18n';

import BackButton from './back_to_filters';
import MeetingBox from './meeting_box';

const Meetings = () => {
  const dispatch = useDispatch();
  const meetings = useSelector(selectMeetings);
  const getMeetings = () => dispatch(fetchMeetings());

  useEffect(() => { getMeetings(); }, []);

  return (
    <div id="bet-finder" className="bet-finder page--filters">
      <BackButton />
      {meetings.loaded ? (
        MEETING_DAYS.map((day) => meetings[day].length > 0 && (
          <MeetingBox
            key={day}
            title={t(`javascript.bet_finder.${day}_races`)}
            meetings={meetings[day]}
          />
        ))
      ) : <Spinner />}
    </div>
  );
};

export default Meetings;
