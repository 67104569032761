import PropTypes from 'prop-types';

const FilterBox = ({ title, children, className }) => (
  <>
    <h3 className="bvs-header">{title}</h3>
    <div className={`bvs-card bf-card ${className}`}>
      {children}
    </div>
  </>
);

FilterBox.defaultProps = {
  className: '',
};

FilterBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default FilterBox;
