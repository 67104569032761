import { t } from 'bv-i18n';

import FilterBox from './filter_box';
import FilterPanel from './filter_panel';
import FilterItem from './filter_item';

const HorseOdds = () => (
  <FilterBox title={t('javascript.bet_finder.horse_odds')}>
    <FilterPanel icons>
      <FilterItem
        icons
        className="bf-card__content-item-icon--short-odds"
        type="short_odds"
        title="Short odds"
      />
      <FilterItem
        icons
        className="bf-card__content-item-icon--medium-odds"
        type="medium_odds"
        title="Medium odds"
      />
      <FilterItem
        icons
        className="bf-card__content-item-icon--long-odds"
        type="long_odds"
        title="Long odds"
      />
    </FilterPanel>
  </FilterBox>
);

export default HorseOdds;
