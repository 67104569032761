import { Icon, Link } from 'bv-components';
import { t } from 'bv-i18n';
import PropTypes from 'prop-types';

const BetFinderHeader = ({ sportId }) => (
  <div className="bf-header">
    <h3 className="bvs-h4 bf-header__title">{t('javascript.bet_finder.betfinder')}</h3>
    <Link to={`/sports/${sportId}/bet-finder/info`} className="bf-header__info">
      <Icon id="info" />
    </Link>
  </div>
);

BetFinderHeader.propTypes = {
  sportId: PropTypes.string.isRequired,
};

export default BetFinderHeader;
