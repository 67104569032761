import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { useHistory, useParams } from 'react-router-dom';

import { selectEventIds } from 'BetFinder/selectors/bet_finder';
import FilterBox from './filter_box';
import FilterPanel from './filter_panel';
import MeetingItem from './meeting_item';

const MeetingBox = ({ title, meetings }) => {
  const history = useHistory();
  const { sportId } = useParams();
  const eventIds = useSelector(selectEventIds);

  return (
    <FilterBox title={title}>
      {meetings.map((meeting) => (
        <FilterPanel key={meeting.id} className="bf-card__meetings">
          <MeetingItem
            label={meeting.name}
            eventIds={meeting.events.map((event) => event.id)}
            active={_(eventIds).isEqual(
              meeting.events.map((event) => event.id),
            )}
            sportId={sportId}
            history={history}
          />
          {meeting.events.map((event) => (
            <MeetingItem
              label={event.title}
              key={event.id}
              eventIds={[event.id]}
              active={eventIds.includes(event.id)}
              sportId={sportId}
              history={history}
            />
          ))}
        </FilterPanel>
      ))}
    </FilterBox>
  );
};

MeetingBox.propTypes = {
  title: PropTypes.string.isRequired,
  meetings: PropTypes.instanceOf(Array).isRequired,
};

export default MeetingBox;
