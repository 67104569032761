import { createSelector } from 'reselect';
import { values, isEqual } from 'underscore';
import { initialState } from 'BetFinder/ducks/bet_finder_store';
import { FILTER_NAMES } from 'BetFinder/helpers/constants';
import {
  filterActive, getActiveDay, isTomorrow, transformObjToArray,
} from 'BetFinder/helpers/helpers';
import { t } from 'bv-i18n';

const selectType = (state, ownProps) => ownProps.type;

export const betFinderDomain = (state) => (state ? state.betFinderStore : initialState);

export const selectFilterValue = createSelector(
  [betFinderDomain, selectType],
  (subdomain, type) => subdomain[type] || 0,
);

export const selectFilterParams = createSelector(
  betFinderDomain,
  (state) => ({
    eventIds: state.eventIds,
    filters: {
      starRating: {
        star1: filterActive(state.star_1),
        star2: filterActive(state.star_2),
        star3: filterActive(state.star_3),
        star4: filterActive(state.star_4),
        star5: filterActive(state.star_5),
      },
      hasWonLastRace: filterActive(state.has_won_last_race),
      hasWonOverDistance: filterActive(state.has_won_over_distance),
      hasWonOverCourse: filterActive(state.has_won_over_course),
      improvingHorses: filterActive(state.improving_horses),
      price: {
        shortOdds: filterActive(state.short_odds),
        mediumOdds: filterActive(state.medium_odds),
        longOdds: filterActive(state.long_odds),
      },
      bestBackedHorse: filterActive(state.best_backed_horse),
      trainerInForm: filterActive(state.trainer_in_form),
      jockeyInForm: filterActive(state.jockey_in_form),
      beatenFavoriteLastTime: filterActive(state.beaten_favorite_last_time),
      hasRunRecently: filterActive(state.has_run_recently),
    },
  }),
);

export const selectActiveFilters = createSelector(
  betFinderDomain,
  (state) => FILTER_NAMES.filter((field) => filterActive(state[field])),
);

export const selectResultParams = createSelector(
  [selectFilterParams, betFinderDomain],
  (params, substate) => ({
    ...params,
    result_day: getActiveDay(),
    last_id: substate.runners[substate.runners.length - 1]?.opponent_id || '',
  }),
);

export const selectFilterValues = createSelector(
  selectFilterParams,
  (filters) => (
    values(transformObjToArray({}, filters)).filter((value) => value === true).length
  ),
);

export const selectEventIds = createSelector(
  betFinderDomain,
  (substate) => substate.eventIds,
);

export const selectMeetings = createSelector(
  betFinderDomain,
  (substate) => substate.meetings,
);

export const selectAllMeetings = createSelector(
  selectMeetings,
  (meetings) => [...meetings.today, ...meetings.tomorrow],
);

export const selectMeetingsName = createSelector(
  [selectEventIds, selectAllMeetings],
  (eventIds, meetings) => {
    if (!eventIds.length) {
      return t('javascript.bet_finder.all_races');
    }

    return meetings.map((meeting) => {
      if (
        isEqual(eventIds, meeting.events.map((event) => event.id))
      ) {
        return `${meeting.name} - ${t('javascript.bet_finder.all_races')}`;
      }
      if (eventIds.length === 1) {
        const event = meeting.events.find((item) => item.id === eventIds[0]);
        if (event) {
          return `${meeting.name} - ${event.title}`;
        }
      }
      return null;
    })
      .find((item) => item !== null) || t('javascript.bet_finder.all_races');
  },
);

export const selectTotalCount = createSelector(
  betFinderDomain,
  (substate) => substate.total_count,
);

export const selectEventIdsDay = createSelector(
  selectEventIds,
  selectMeetings,
  (eventIds, meetings) => (eventIds.length && isTomorrow(eventIds[0], meetings)
    ? 'TOMORROW'
    : 'TODAY'),
);

export const selectRunners = createSelector(
  betFinderDomain,
  (substate) => substate.runners,
);

export const selectHasMoreResult = createSelector(
  betFinderDomain,
  (substate) => substate.has_more_result,
);

export const selectFetching = createSelector(
  betFinderDomain,
  (substate) => substate.fetching,
);
