import { t } from 'bv-i18n';

const EmptyResultBox = () => (
  <div className="bf-no-results">
    <p className="bvs-h4">{t('javascript.bet_finder.no_horses')}</p>
    <p className="bvs-h5">{t('javascript.bet_finder.try_again')}</p>
  </div>
);

export default EmptyResultBox;
