import { t } from 'bv-i18n';
import { assetPath } from 'bv';
import { linkLocalizer } from 'bv-helpers/locale';
import { preEventPath } from 'bv-helpers/routes';
import PropTypes from 'prop-types';
import ResultOutcome from './result_outcome';

const { missingSilkImageUrl } = window;

const ResultItem = ({ runner }) => (
  <div key={runner.outcome_id} className="bvs-card bf-results">
    <a
      className="bvs-button-sport bf-results__race"
      href={
        linkLocalizer(preEventPath({
          sportEventPathId: 364,
          meetingId: runner.race.meeting_id,
          eventId: runner.race.event_id,
        }))
      }
    >
      <strong>{ runner.race.course_name }</strong>
&nbsp;
      { runner.race.event_title }
    </a>

    <div className="bf-results__details">
      <div className="bf-results__details-silk">
        <img
          className="silk"
          alt={runner.silk_code}
          onError={(e) => {
            if (e.target.src !== missingSilkImageUrl) {
              e.target.src = missingSilkImageUrl;
            }
          }}
          src={assetPath(`/silks/${runner.silk_code}`)}
        />
        <span>{`${runner.entry_number} (${runner.draw})`}</span>
      </div>
      <div className="bf-results__details-desc">
        <h4 className="bvs-h4">{ runner.runner_name }</h4>
        <p>{ runner.trainer_name }</p>
        <p>{ runner.jockey_name }</p>
        <p>{`${t('javascript.bet_finder.horse_age')} ${runner.age}`}</p>
        <p>{`${t('javascript.bet_finder.horse_weight')} ${runner.weight}`}</p>
        <p>{`${t('javascript.bet_finder.horse_form')} ${runner.form}`}</p>
      </div>
      <div className="bf-results__details-price">
        <ResultOutcome
          id={runner.outcome_id}
          description={runner.market.pltDes}
          eachway={runner.market.ew}
        />
      </div>
    </div>
    <div className="bf-results__details-horse-stars">
      <strong>{t('javascript.bet_finder.star_rating')}</strong>
      <span className={`bf-results__details-horse-stars--rating star-${runner.star_rating}`} />
    </div>
    <div className="bf-results__details-timeform">
      <strong>{t('javascript.bet_finder.timeform')}</strong>
      {t('javascript.bet_finder.timeform_says')}
      {' '}
      { runner.comment }
    </div>
  </div>
);

ResultItem.propTypes = {
  runner: PropTypes.instanceOf(Object),
};

ResultItem.defaultProps = {
  runner: {},
};

export default ResultItem;
