import { t } from 'bv-i18n';

import BackButton from './back_to_filters';

const InfoPage = () => (
  <div className="bet-finder">
    <BackButton />

    <div className="bvs-card">
      <h3 className="bvs-h4">{t('javascript.bet_finder.info.title_main')}</h3>
      <hr />
      <p>{t('javascript.bet_finder.info.text_main')}</p>
      {[...Array(13)].map((e, i) => (
        <>
          <h4 className="bvs-h4">{t(`javascript.bet_finder.info.title_${i + 1}`)}</h4>
          <p>{t(`javascript.bet_finder.info.text_${i + 1}`)}</p>
        </>
      ))}
    </div>
  </div>
);

export default InfoPage;
