import { Icon, Link } from 'bv-components';
import { t } from 'bv-i18n';
import PropTypes from 'prop-types';

const ResultBackButton = ({ sportId }) => (
  <div className="bf-back-wrapper">
    <Link className="bf-back-button" to={`/sports/${sportId}/bet-finder`}>
      <Icon id="arrow-left" />
      {t('javascript.bet_finder.show_filters')}
    </Link>
  </div>
);

ResultBackButton.propTypes = {
  sportId: PropTypes.number.isRequired,
};

export default ResultBackButton;
