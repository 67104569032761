import { t } from 'bv-i18n';

import FilterBox from './filter_box';
import FilterPanel from './filter_panel';
import FilterItem from './filter_item';

const HorseAbility = () => (
  <FilterBox title={t('javascript.bet_finder.horse_ability')}>
    <FilterPanel title={t('javascript.bet_finder.star_rating')} icons>
      {[...Array(5).keys()].map((item) => (
        <FilterItem
          icons
          className={`bf-card__content-item-icon--star-${item + 1}`}
          type={`star_${item + 1}`}
        />
      ))}
    </FilterPanel>
    <FilterPanel icons>
      <FilterItem
        icons
        className="bf-card__content-item-icon--has-won-over-course"
        type="has_won_over_course"
        title={t('javascript.bet_finder.has_won_over_course')}
      />
      <FilterItem
        icons
        className="bf-card__content-item-icon--has-won-over-distance"
        type="has_won_over_distance"
        title={t('javascript.bet_finder.has_won_over_distance')}
      />
      <FilterItem
        icons
        className="bf-card__content-item-icon--improving-horses"
        type="improving_horses"
        title={t('javascript.bet_finder.improving_horses')}
      />
    </FilterPanel>
  </FilterBox>
);

export default HorseAbility;
