import { Button, Icon } from 'bv-components';
import { t } from 'bv-i18n';
import PropTypes from 'prop-types';

const BetFinderCourseSelect = ({ sportId, meetingName }) => (
  <>
    <h3 className="bvs-h5 bf-course-select-title">{t('javascript.bet_finder.select_race')}</h3>
    <div className="bf-course-select">
      <Button
        to={`/sports/${sportId}/bet-finder/meetings`}
        secondary
        className="bf-course-select__button"
      >
        {meetingName}
        <Icon id="arrow-right" inverted />
      </Button>
    </div>
  </>
);

BetFinderCourseSelect.propTypes = {
  sportId: PropTypes.string.isRequired,
  meetingName: PropTypes.string.isRequired,
};

export default BetFinderCourseSelect;
