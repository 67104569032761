import PropTypes from 'prop-types';
import classnames from 'classnames';

const FilterPanel = ({
  title, children, icons, className,
}) => (
  <>
    {title && (<h4 className="bvs-h5 bf-card__title">{title}</h4>)}
    <div
      className={classnames({
        'bf-card__content': true,
        'bf-card__content-with-icons': icons,
        [className]: className,
      })}
    >
      {children}
    </div>
  </>
);

FilterPanel.defaultProps = {
  icons: false,
  className: '',
};

FilterPanel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  icons: PropTypes.bool,
  className: PropTypes.string,
};

export default FilterPanel;
