import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ContentLayout } from 'bv-components';
import { resetApp } from 'BetFinder/ducks/bet_finder_store';

import BetFinderFilter from './components/filter_page';
import BetFinderInfoPage from './components/info_page';
import BetFinderMeetings from './components/meetings_page';
import BetFinderResultPage from './components/result_page';
import betFinderStore from './ducks/bet_finder_store';

const { addReducers } = window.reduxState;

addReducers({
  betFinderStore,
});

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(resetApp()), []);

  return (
    <ContentLayout>
      <Switch>
        <Route path="/sports/:sportId/bet-finder" component={BetFinderFilter} exact />
        <Route path="/sports/:sportId/bet-finder/info" component={BetFinderInfoPage} exact />
        <Route path="/sports/:sportId/bet-finder/meetings" component={BetFinderMeetings} exact />
        <Route path="/sports/:sportId/bet-finder/results" component={BetFinderResultPage} exact />
      </Switch>
    </ContentLayout>
  );
};

export default App;
