import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';
import { buildResultDaySearch, getActiveDay } from 'BetFinder/helpers/helpers';

const ResultDayButton = ({ day }) => {
  const location = useLocation();
  const activeDay = getActiveDay();

  const search = useMemo(() => buildResultDaySearch(day), [location.search]);

  return (
    <Button
      className={classnames('bvs-button-chip', {
        active: activeDay === day.toUpperCase(),
      })}
      to={{
        pathname: location.pathname,
        search,
      }}
      replace
    >
      {t(`javascript.bet_finder.${day}`)}
    </Button>
  );
};

ResultDayButton.propTypes = {
  day: PropTypes.string.isRequired,
};

export default ResultDayButton;
