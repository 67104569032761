import { getJSON, postFormData } from 'bv-fetch';
import { transformObjToFormData } from 'BetFinder/helpers/helpers';

export const postMatchCountForm = (params) => {
  const data = new URLSearchParams();
  transformObjToFormData(data, params, null);
  return postFormData('/bv_bet_finder/match_count', data);
};

export const fetchResultsJson = (params) => {
  const { eventIds, ...rest } = params;
  const postParams = { event_ids: eventIds, ...rest };

  const data = new URLSearchParams();
  transformObjToFormData(data, postParams, null);
  return postFormData('/bv_bet_finder/results', data);
};

export const fetchMeetingsJson = () => getJSON('/bv_bet_finder/meetings');
